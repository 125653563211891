import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons'; // This is correct
import { faTwitter, faTelegramPlane } from '@fortawesome/free-brands-svg-icons'; // Update this line
import foto1 from './foto1.png';
import foto2 from './foto2.png';
import foto3 from './foto3.png';
import foto4 from './foto4.png';
import foto5 from './foto5.png';
import foto6 from './foto6.png';
import foto7 from './foto7.png';
import foto8 from './foto8.png';
import foto9 from './foto9.png';


const fallDuration = 4;
const AnimatedImage = ({ src, xOffset = 0, yOffset = 0 }) => {
  // Base initial position more spread out and add offsets for each image
  const xInitial = Math.random() * (window.innerWidth - 150) - 80 + xOffset; // Spread out more and add xOffset
  const yInitial = Math.random() * (window.innerHeight - 150) - 100 + yOffset; // Spread out more and add yOffset

  // Adjust animation movement to be more dynamic and use offsets to vary the animation range
  const animateX = xInitial + (Math.random() * 100 - 50) + xOffset; // Wider movement range + xOffset
  const animateY = yInitial + (Math.random() * 100 - 50) + yOffset; // Wider movement range + yOffset

  return (
    <motion.img
      src={src}
      initial={{ x: xInitial, y: yInitial }}
      animate={{ x: animateX, y: animateY }}
      transition={{
        duration: 2, // Consider varying this per image for more dynamism
        yoyo: Infinity, // Continuous back-and-forth animation
        repeatDelay: 1 // Optional delay between animations for more natural movement
      }}
      className="absolute w-32 h-32" // Adjust size as needed
    />
  );
};



const PooEmojiFall = () => {
  const randomX = Math.random() * window.innerWidth;
  const randomDelay = Math.random() * 2;
  return (
    <motion.div
      initial={{ y: -50, x: randomX }}
      animate={{ y: [null, window.innerHeight + 20], x: [null, randomX] }}
      transition={{ duration: fallDuration, delay: randomDelay, repeat: Infinity }}
      className="poo-emoji absolute text-4xl"
    >
      💩
    </motion.div>
  );
};

const App = () => {
  const [showIceCream, setShowIceCream] = useState(false);
  const pooEmojisCount = 50;
  const contractAddress = 'xxxxxxxxxxxxxxxxxxxxx';

  const copyContractAddress = () => {
    navigator.clipboard.writeText(contractAddress);
    setShowIceCream(true);
    setTimeout(() => setShowIceCream(false), 2000);
  };



  return (
    <div className="App min-h-screen w-screen overflow-hidden flex flex-col items-center justify-center text-white relative">
      <AnimatedImage src={foto1} xOffset={-100} yOffset={-200} />
      <AnimatedImage src={foto2} xOffset={200} yOffset={100} />
      <AnimatedImage src={foto3} xOffset={-150} yOffset={250} />
      <AnimatedImage src={foto4} xOffset={100} yOffset={-250} />
      <AnimatedImage src={foto5} xOffset={-200} yOffset={150} />
      <AnimatedImage src={foto6} xOffset={250} yOffset={-100} />
      <AnimatedImage src={foto7} xOffset={-300} yOffset={50} />
      <AnimatedImage src={foto8} xOffset={350} yOffset={-50} />
      <AnimatedImage src={foto9} xOffset={-400} yOffset={100} />
      <div className="fixed top-0 left-0 p-4">
        <a href="https://twitter.com/basedpooAI" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} className="text-3xl mr-4" />
        </a>
        <a href="https://t.me/basedpoo" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTelegramPlane} className="text-3xl" />
        </a>
      </div>
      <div className="fixed top-0 right-0 p-4">
        <a href="/docs" className="text-lg">Docs</a>
      </div>
      <div className="p-5 bg-black bg-opacity-50 rounded-lg text-center">
        <h1 className="text-4xl mb-5 text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-yellow-600">
          BASEDPOO - No pee, just poo
        </h1>
        <p className="mb-9">Poocentralized market maker and auto liquidity poovider for the POOLANA</p>
        <div className="flex flex-col items-center space-y-4">
          <a href="https://dexscreener.com/solana/x" target="_blank" rel="noopener noreferrer">
            <button className="px-6 py-3 bg-yellow-600 text-white rounded-lg shadow-lg w-full">Buy Now</button>
          </a>
          <a href="https://solscan.io/token/x" target="_blank" rel="noopener noreferrer">
            <button className="px-6 py-3 bg-yellow-800 text-white rounded-lg shadow-lg w-full">Check Contract</button>
          </a>
          <div className="relative w-full">
            <input
              type="text"
              value={contractAddress}
              readOnly
              className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg"
            />
            <button
              onClick={copyContractAddress}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white"
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </div>
          <AnimatePresence>
            {showIceCream && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="text-2xl"
              >
                🍦🍦🍦
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <AnimatePresence>
        {Array.from({ length: pooEmojisCount }).map((_, index) => (
          <PooEmojiFall key={index} />
        ))}
      </AnimatePresence>
    </div>
  );
};

export default App;